<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id: " v-if="data.id">
                        <Input v-model="data.id"  disabled></Input>
                    </FormItem>
                    <FormItem label="子比赛: ">
                        <Row v-for="(sub_id, index) in data.sub_ids" :key="sub_id">
                            <Col span="16" >
                                <span class="text-link">{{ sub_id }}</span> 
                            </Col>
                            <Col span="4" >
                                <Icon type="md-close-circle"  @click.native="data.sub_ids.splice(index, 1)" />
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <matchSearch :sport_id="sport_id" v-model="new_match" @onChooseMatch="new_match.id? data.sub_ids.push(new_match.id): ''"></matchSearch>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="赛事: ">
                        <Row>
                            <Col span="20" >
                                <uniqueTournamentSearch v-model="data.unique_tournament" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'unique_tournament_id'] === 1? 'locked-input': '' "></uniqueTournamentSearch>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'unique_tournament_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="unique_tournament_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="赛季: ">
                        <Row>
                            <Col span="20" >
                                <seasonSearch v-model="data.season" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'season_id'] === 1? 'locked-input': '' "></seasonSearch>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'season_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="season_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="阶段: ">
                        <Row>
                            <Col span="20" >
                                <tournamentSearch v-model="data.tournament" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'tournament_id'] === 1? 'locked-input': '' "></tournamentSearch>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'tournament_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="tournament_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="主队: ">
                        <Row>
                            <Col span="20" >
                                <teamSearch v-model="data.home" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'home_team_id'] === 1? 'locked-input': '' "></teamSearch>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'home_team_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="home_team_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="客队: ">
                        <Row>
                            <Col span="20" >
                                <teamSearch v-model="data.away" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'away_team_id'] === 1? 'locked-input': '' "></teamSearch>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'away_team_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="away_team_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="场馆: ">
                        <Row>
                            <Col span="20" >
                                <venueSearch v-model="data.venue" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'venue_id'] === 1? 'locked-input': '' "></venueSearch>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'venue_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="venue_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="状态: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.status_id" :class="locks[String(type) + '-1-' + 'status_id'] === 1? 'locked-select': '' ">
                                    <Option v-for="item in $conf['ballsports']['status'][sport_id]['map']" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-' + 'status_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="status_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="时间: " >
                        <Row>
                            <Col span="20" >
                                <DatePicker 
                                    type="datetime" 
                                    format="yyyy-MM-dd HH:mm" 
                                    :class="locks[String(type) + '-1-' + 'match_time'] === 1? 'locked-input': '' "
                                    style="width: 100%"
                                    v-model="match_time"
                                >
                                </DatePicker>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'match_time']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="match_time"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="盘数(BO): " v-if="[3].indexOf(sport_id) > -1">
                        <Row>
                            <Col span="20" >
                                <!-- <InputNumber v-model="data.bestof" number :class="locks[String(type)+'-1-'+'bestof'] === 1? 'locked-input': '' "></InputNumber> -->
                                <Input v-model="data.bestof" number :class="locks[String(type)+'-1-'+'bestof'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'bestof']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="bestof"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="是否中立: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.neutral" :class="locks[String(type) + '-1-' + 'neutral'] === 1? 'locked-select': '' ">
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'neutral']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="neutral"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="删除: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
            <TabPane label="额外信息" name="2">
                <Form label-position="left" :label-width="80">
                    <FormItem label="天气: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.weather.id" :class="locks[String(type) + '-2-' + 'weather_id'] === 1? 'locked-select': '' ">
                                    <Option v-for="item in $conf.ballsports.weather"  :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'weather_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="2" _key="weather_id"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="温度(℃): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.weather.temp" number :class="locks[String(type)+'-2-'+'temp'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'temp']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="2" _key="temp"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="风速(m/s): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.weather.wind" number :class="locks[String(type)+'-2-'+'wind'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'wind']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="2" _key="wind"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="湿度(%): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.weather.humidity" number :class="locks[String(type)+'-2-'+'humidity'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'humidity']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="2" _key="humidity"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="气压(mmHg): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.weather.pressure" number :class="locks[String(type)+'-2-'+'pressure'] === 1? 'locked-input': '' " number></Input>
                            </Col>
                            <Col span="2" offset='1'>
                                <Lock v-model="locks[String(type)+'-2-'+'pressure']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="match_id" :type="type" :sub_type="2" _key="pressure"></Lock>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
        </Tabs>

        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>
<script>

import { getMatch, updateMatch } from '@/api/ballsports/base/match';
import teamSearch from '../team/search.vue';
import uniqueTournamentSearch from '../unique_tournament/search.vue';
import seasonSearch from '../season/search.vue';
import venueSearch from '../venue/search.vue';
import tournamentSearch from '../tournament/search.vue';
import Lock from '../lock.vue';

import moment from 'moment';
import matchSearch from './search.vue';

export default {
    name: "match-edit",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        Lock,
        teamSearch,
        uniqueTournamentSearch,
        seasonSearch,
        tournamentSearch,
        venueSearch,
        matchSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 1,
            type: 5, 
            spinShow: false,
            data: {
                sport_id: self.sport_id,
                home: {},
                away: {},
                weather: {},
                unique_tournament: {},
                season: {},
                tournament: {},
                venue: {},
                match_time: parseInt( Date.parse(new Date())/1000 ),
            },
            new_match: {
                'id': 0,
                home: {},
                away: {},
            },
            locks: {},
        }
    },
    methods: {
        _init_data(){
            this.data = {
                    sport_id: this.sport_id,
                    home: {},
                    away: {},
                    weather: {},
                    unique_tournament: {},
                    season: {},
                    tournament: {},
                    venue: {},
                    match_time: parseInt( Date.parse(new Date())/1000 ),
                }
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.match_id > 0){
                var params = {
                    sport_id: self.sport_id,
                    match_id: self.match_id,
                }
                getMatch( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks;
                        }else{
                            this.data = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            // self.match_id = 0; 不能直接修改probs传参
            self.emit("cancle")
        },
        save() {
            let self = this;
            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                unique_tournament_id: self.data.unique_tournament.id,
                tournament_id: self.data.tournament.id,
                season_id: self.data.season.id,
                home_team_id: self.data.home.id,
                away_team_id: self.data.away.id,
                venue_id: self.data.venue.id,
                match_time: self.data.match_time,
                status_id: self.data.status_id,
                neutral: self.data.neutral,
                bestof: self.data.bestof,
                deleted: self.data.deleted,
                // week: self.data.week,
                sub_ids: self.data.sub_ids,
            };
            if ( self.data.weather ){
                params.weather = {
                    id: parseInt( self.data.weather.id ),
                    temp: parseInt( self.data.weather.temp),
                    wind: parseInt( self.data.weather.wind),
                    humidity: parseInt( self.data.weather.humidity),
                    pressure: parseInt( self.data.weather.pressure),
                }
            }else{
                params.weather = {}
            }
            updateMatch(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
    },
    watch: {
        // match_id(value){
        //     let self = this;
        //     if (value){
        //         self.getData();
        //     };
        // },
        'match_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
        home_logo: {
            get: function(){
                return this.data.home.logo ? this.$conf.ballsports.img_prefix + '/v2/team/' + this.data.home.logo: ''
            },
            set: function(){
            }
        },
        away_logo:{
            get: function(){
                return this.data.away.logo ? this.$conf.ballsports.img_prefix + '/v2/team/' + this.data.away.logo: ''
            },
            set: function(){
            }
        },
        match_time: {
            get: function () {
                if (this.data.match_time){
                    return moment.unix(this.data.match_time).toDate();
                }else{
                    return parseInt( Date.parse(new Date())/1000 ) ;
                }
            },
            set: function (newValue) {
                this.data.match_time = moment(newValue).unix();
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>