<template>
  <div>
    <Drawer
        v-if='refuseFbBk()'
        v-model="drawer_detail"
        width="830"
        :mask-closable="true"
        :styles="drawer_styles"
    >
        <p slot="header" style="color:#090C13;">
            <!-- <Icon type="ios-information-circle"></Icon>
                <span>Delete confirmation</span> -->
            <Row>
                <Col span="6" class='text-main'>
                    比赛ID: {{detail_match_id}}
                </Col>
                <Col span="10">
                    <span class='text-main' v-if="detail_match_info.home && detail_match_info.away">
                        {{detail_match_info.home.name || ''}} vs {{detail_match_info.away.name || ''}}
                    </span>
                </Col>
                <!-- <Col span="6" class='text-link' style="text-align: right">
                    <router-link :to="{path:'/ballsports/base/match/detail', 
                                        query:{
                                            'sport_id': sport_id, 
                                            'match_id':detail_match_id,
                                        }}">在新标签打开</router-link>
                </Col> -->
            </Row>
        </p>
        <matchDetail :sport_id="sport_id" :match_id="detail_match_id" ></matchDetail>
    </Drawer>  
    
    <Drawer
        v-if='refuseFbBk()'
        v-model="drawer_relation"
        width="500"
        :mask-closable="true"
        :closable="false"
    >
        <matchRelation :sport_id="sport_id" :match_id="relation_match_id" @closePage="closeRelationDrawer"></matchRelation>
    </Drawer>  

    <Drawer
    v-if='refuseFbBk()'
        v-model="drawer_edit_match"
        width="500"
        :mask-closable="true"
    >
        <matchEdit :sport_id="sport_id" :match_id="edit_match_id" @closePage="closeEditDrawer"></matchEdit>
    </Drawer>  

    <!-- 模态框 - 编辑赛事赛季队伍等信息-->
    <Modal v-if='refuseFbBk()' v-model="modal_edit_unique_tournament" draggable footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <!-- <Icon type="ios-information-circle"></Icon> -->
            <span>编辑赛事</span>
        </div>
        <div class="modal-div-containter">
            <uniqueTournamentEdit :sport_id="sport_id" :unique_tournament_id="edited_unique_tournament_id" @closePage="closeEditModalUniqueTournament"></uniqueTournamentEdit>
        </div>
    </Modal>
    <Modal v-if='refuseFbBk()' v-model="modal_edit_season" draggable footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <!-- <Icon type="ios-information-circle"></Icon> -->
            <span>编辑赛季</span>
        </div>
        <div class="modal-div-containter">
            <seasonEdit :sport_id="sport_id" :season_id="edited_season_id" @closePage="closeEditModalSeason"></seasonEdit>
        </div>
    </Modal>
    <Modal v-if='refuseFbBk()' v-model="modal_edit_tournament" draggable footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <!-- <Icon type="ios-information-circle"></Icon> -->
            <span>编辑阶段</span>
        </div>
        <div class="modal-div-containter">
            <tournamentEdit :sport_id="sport_id" :tournament_id="edited_tournament_id" @closePage="closeEditModalTournament"></tournamentEdit>
        </div>
    </Modal>
    <Modal v-if='refuseFbBk()' v-model="modal_edit_team" draggable footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <!-- <Icon type="ios-information-circle"></Icon> -->
            <span>编辑战队</span>
        </div>
        <div class="modal-div-containter">
            <teamEdit :sport_id="sport_id" :team_id="edited_team_id" @closePage="closeEditModalTeam"></teamEdit>
        </div>
    </Modal>
    <Row style="margin-bottom:10px;">
        <Col >
            <!-- 每一款运动的状态都不一样 -->
            <RadioGroup v-model="status_ids_str" type="button" @on-change="onStatusIdsStrChange">
                <Radio v-for="(item, index) in status_union_dic" :key="index" :label="item.ids.join(',')">
                    {{ item.name }}
                </Radio>
            </RadioGroup>
        </Col>
        <Col span="4">
            <Select v-model="status_ids" multiple @on-change="onStatusIdsChange" placeholder="多选状态">
                <Option v-for="(item, index) in status_map" :value="item.id" :key="index">{{ item.name }}</Option>
            </Select>
        </Col>
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="6" style="text-align: left">
            <DatePicker format="yyyy-MM-dd HH:mm" :options="date_options" v-model="datarange" type="daterange" placement="bottom-end"
                 placeholder="日期范围" style="width: 100%" @on-change='onDatarangeChange' editable></DatePicker>
        </Col>
        <Col style="text-align: right">
            <Checkbox v-model="deleted" border>筛选删除</Checkbox> 
        </Col>
        <Col >
            <Button type="success"  @click="add">新增</Button>
        </Col>
    </Row>
    <fitTable 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getMatch } from '@/api/ballsports/base/match';
import moment from 'moment';
import matchRelation from './relation.vue';
import matchDetail from './detail.vue';
import matchEdit from './edit.vue';
import {getImgUrl} from '@/utils/util';
// 编辑选项
import uniqueTournamentEdit from '../unique_tournament/edit.vue';
import tournamentEdit from '../tournament/edit.vue';
import seasonEdit from '../season/edit.vue';
import teamEdit from '../team/edit.vue';
import fitTable from '../../basic/table.vue';

const relationButton = (vm, h, params) => {
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'success',
                size: 'small'
            },
            on: {
                click: () => {
                    // vm.edit_data = JSON.parse(JSON.stringify(params.row))
                    vm.relation_match_id = params.row.id;
                    vm.drawer_relation = true;
                }
            }
        },
        '关联'
    )
}

const detailButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
                size: 'small'
            },
            on: {
                click: () => {
                    // vm.edit_data = JSON.parse(JSON.stringify(params.row))
                    vm.detail_match_id = params.row.id;
                    vm.detail_match_info.home = params.row.home;
                    vm.detail_match_info.away = params.row.away;
                    vm.drawer_detail = true
                }
            }
        },
        '详情'
    )
}

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id)
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    // vm.edit_data = JSON.parse(JSON.stringify(params.row))
                    vm.edit_match_id = params.row.id;
                    vm.drawer_edit_match = true;
                }
            }
        },
        '编辑'
    )
}

// 篮球 文字直播
const logButton = (vm, h, params) => {
  return h(
    'Button',
    {
      props: {
        type: 'success',
      },
      on: {
        click: () => {
          let query = { match_id: params.row.id, sport_id: params.row.sport_id }
          // vm.$router.push({path: '/numer', query: query});
          let resolve_data = vm.$router.resolve({ path: '/ballsports/'+ 'basketball' +'/match/text_live/detail', query: query })
          window.open(resolve_data.href, '_blank')
        }
      }
    },
    '文字直播'
  )
}
// 篮球技术统计
const playerStatsButton = (vm, h, params) => {
  return h(
    'Button',
    {
      props: {
        type: 'warning',
      },
      on: {
        click: () => {
          let query = { match_id: params.row.id, sport_id: params.row.sport_id }
          // vm.$router.push({path: '/numer', query: query});
          let resolve_data = vm.$router.resolve({ path: '/ballsports/'+ 'basketball' +'/match/player_stat', query: query })
          window.open(resolve_data.href, '_blank')
        }
      }
    },
    '技术统计'
  )
}

// 指数
const oddsButton = (vm, h, params) => {
  return h(
    'Button',
    {
      props: {
        type: 'success',
      },
      on: {
        click: () => {
            let query = { match_id: params.row.id, sport_id: params.row.sport_id }
            // 路由跳转到编辑页面
            let sport = vm.$conf.ballsports.sports[params.row.sport_id];
            if (sport){
                let path = '/ballsports/'+ sport.name_en +'/odds';
                let resolve_data = vm.$router.resolve({ path: path, query: query })
                window.open(resolve_data.href, '_blank')
            }else{
                vm.$Message.error("暂不支持的运动类型")
            }
        }
      }
    },
    '指数'
  )
}

// 足球事件
const incidentsButton = (vm, h, params) => {
  return h(
    'Button',
    {
      props: {
        type: '',
      },
      on: {
        click: () => {
          let query = { id: params.row.id, sport_id: params.row.sport_id }
          let sport = vm.$conf.ballsports.sports[params.row.sport_id];
          let path = '/v3/'+ sport.name_en ;
          let resolve_data = vm.$router.resolve({ path: path, query: query })
          window.open(resolve_data.href, '_blank')
        }
      }
    },
    '事件'
  )
}

export default {
    name: "match-list",
    props: {
        sport_id: Number,   // 运动id
    },
    components: {
        matchRelation,
        matchDetail,
        matchEdit,
        // 编辑
        uniqueTournamentEdit,
        seasonEdit,
        tournamentEdit,
        teamEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            deleted: false,
            drawer_detail: false,
            drawer_relation: false,
            drawer_edit_match: false,
            relation_match_id: 0,
            detail_match_id: 0,
            edit_match_id : 0,
            detail_match_info: {
                home: {},
                away: {},
            },
            search_key: '',
            start_date: parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            end_date  : parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            datarange : [new Date(new Date().toLocaleDateString()),new Date(new Date().toLocaleDateString())],
            date_options: {
                shortcuts: [
                    {
                        text: '1个月',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '1周',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '6小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 6);
                            return [start, end];
                        }
                    },
                    {
                        text: '3小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 3);
                            return [start, end];
                        }
                    },
                    {
                        text: '30分钟',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 1800 * 1000);
                            return [start, end];
                        }
                    },
                    {
                        text: '昨天',
                        value () {
                            const end = new Date(new Date().toLocaleDateString());
                            const start = new Date();
                            start.setTime(end.getTime() - 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '今天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            end.setTime(start.getTime() + 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '明天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            start.setTime(start.getTime() + 3600 * 1000 * 24);
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                            return [start, end];
                        }
                    },
                    
                ]
            },
            status_ids: [],
            status_ids_str: '',

            status_ids_list: [], // 用于搜索
            status_union_dic: {},
            status_map : {}, // 状态map表
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,

            edit_data: {
                uid: 0,
                name: ''
            },
            modal1: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                {
                    title: 'thesports id',
                    align: 'center',
                    width: 100,
                    key: 'thesports_id',
                },
                {
                    title: '子比赛id',
                    align: 'left',
                    key: 'sub_ids',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        var sub_ids = currentRow.sub_ids
                        var _texts = [];
                        sub_ids.forEach((element, _index) => {
                            _texts.push( 
                                h("Row", 
                                    [h('span', 
                                        {   
                                            class: "text-link", 
                                        }, 
                                        element,
                                    )] 
                                )
                            )
                        })
                        return _texts
                    }
                },
                {
                    title: '赛事',
                    align: 'left',
                    key: 'unique_tournament_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        var unique_tournament = currentRow.unique_tournament
                        return h(
                            'Row', unique_tournament ? [
                                // h("Col", {attrs: {span: 2 }}, [h('img', { attrs: { src: getImgUrl(self.sport_id, 'unique-tournament', unique_tournament.logo, ) },
                                //             class: {'match-unique-tournament-logo': true} })],),
                                h("Col", 
                                    {
                                        attrs: {span: 24 }, 
                                    },
                                    [
                                        h('img', { attrs: { src: getImgUrl(self.sport_id, 'unique-tournament', unique_tournament.logo, ) },
                                            class: {'match-unique-tournament-logo': true} }),
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editUniqueTournament(unique_tournament.id);
                                            }
                                        } },  unique_tournament.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editUniqueTournament(unique_tournament.id);
                                            }
                                        } }, unique_tournament.name,  ),
                                    ]
                                ,)
                                // h("Col", {attrs: {span: 4 }}, [],),
                            ] : ''
                        )
                    }
                },
                {
                    title: '赛季',
                    align: 'left',
                    key: 'season_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        var season = currentRow.season;
                        // return h('span', season.name)
                        // return h('router-link',
                        //     {
                        //         class: 'table-link-text',
                        //         attrs:{
                        //             target: "_blank", 
                        //         },
                        //         props: {
                        //             to: {
                        //                 path: '/ballsports/base/season/detail',
                        //                 query: {
                        //                     sport_id: currentRow.sport_id,
                        //                     season_id: season.id
                        //                 }
                        //             },
                        //         }
                        //     },
                        //     season.name
                        // );
                        return season.id > 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    self.editSeason(season.id);
                                }
                            } },  season.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    self.editSeason(season.id);
                                }
                            } }, season.name,  )
                        ]: ''
                    }
                },
                {
                    title: '阶段',
                    align: 'left',
                    key: 'tournament_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        var tournament = currentRow.tournament
                        // return h('span', tournament.name)
                        // return h('router-link',
                        //     {
                        //         class: 'table-link-text',
                        //         attrs:{
                        //             target: "_blank", 
                        //         },
                        //         props: {
                        //             to: {
                        //                 path: '/ballsports/base/tournament/detail',
                        //                 query: {
                        //                     sport_id: currentRow.sport_id,
                        //                     tournament_id: tournament.id
                        //                 }
                        //             },
                        //         }
                        //     },
                        //     tournament.name
                        // );
                        return tournament.id > 0? [
                            h('span', {class: 'text-link', on: {
                                'click':() => {
                                    self.editTournament(tournament.id);
                                }
                            } },  tournament.id + ' ',  ),
                            h('span', {class: 'text-main',on: {
                                'click':() => {
                                    self.editTournament(tournament.id);
                                }
                            } }, tournament.name,  )
                        ]: ''
                    }
                },
                {
                    title: '盘数',
                    width: 60,
                    align: 'center',
                    key: 'bestof',
                },
                
                {
                    title: '主队',
                    key: 'home_team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let homes = currentRow.homes;
                        let home = currentRow.home;
                        let resp = [];
                        if (homes && homes.length > 0){
                            let cols = [];
                            homes.forEach(element => {
                                cols.push(
                                    h('Col', { attrs: { span: 12 }}, 
                                        [h('img', { attrs: { src: getImgUrl(self.sport_id, 'team', element.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })]
                                    ),);
                            });
                            resp.push(h("Row", {attrs: {justify: 'center', }, }, 
                                        cols,
                                    ));
                        }else{
                            resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                        [h('img', { attrs: { src: getImgUrl(self.sport_id, 'team', home.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })],
                                    ));
                        }
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        // h('span', home.name),
                                        // h('router-link',
                                        //     {
                                        //         attrs:{
                                        //             target: "_blank", 
                                        //         },
                                        //         props: {
                                        //             to: {
                                        //                 path: '/ballsports/base/team/detail',
                                        //                 query: {
                                        //                     sport_id: currentRow.sport_id,
                                        //                     team_id: home.id
                                        //                 }
                                        //             },
                                        //         }
                                        //     },
                                        //     home.name
                                        // ),
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(home.id);
                                            }
                                        } },  home.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(home.id);
                                            }
                                        } }, home.name,  )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '比分',
                    key: 'scores',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        var scores = currentRow.scores;
                        if (scores){
                            var ft = scores.ft;
                            if (ft){
                                var param_value = ft.join('-')
                            }else{
                                var param_value = '';
                            }
                        }else{
                            var param_value = '';
                        }
                        return h('span', param_value);
                    }
                },
                {
                    title: '客队',
                    key: 'away_team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let aways = currentRow.aways;
                        let away = currentRow.away;
                        let resp = [];
                        if (aways && aways.length > 0){
                            let cols = [];
                            aways.forEach(element => {
                                cols.push(
                                    h('Col', { attrs: { span: 12 }}, 
                                        [h('img', { attrs: { src: getImgUrl(self.sport_id, 'team', element.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })]
                                    ),);
                            });
                            resp.push(h("Row", {attrs: {justify: 'center',  }, }, 
                                        cols,
                                    ));
                        }else{
                            resp.push(h("Row", {attrs: {justify: 'center',  }, }, 
                                        [h('img', { attrs: { src: getImgUrl(self.sport_id, 'team', away.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })],
                                    ));
                        }
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        // h('span', away.name),
                                        // h('router-link',
                                        //     {
                                        //         attrs:{
                                        //             target: "_blank", 
                                        //         },
                                        //         props: {
                                        //             to: {
                                        //                 path: '/ballsports/base/team/detail',
                                        //                 query: {
                                        //                     sport_id: currentRow.sport_id,
                                        //                     team_id: away.id
                                        //                 }
                                        //             },
                                        //         }
                                        //     },
                                        //     away.name
                                        // ),
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(away.id);
                                            }
                                        } },  away.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(away.id);
                                            }
                                        } }, away.name, )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                // {
                //     title: '场馆',
                //     key: 'venue_id',
                //     render: function (h, params) {
                //         let currentRow = params.row
                //         var venue = currentRow.venue;
                //         return h('span', venue.name)
                //     }
                // },
                // {
                //     title: '场地',
                //     key: 'ground_id'
                // },
                {
                    title: '比赛时间',
                    width: 100,
                    align: 'center',
                    key: 'match_time',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', moment.unix(currentRow.match_time).format('YYYY-MM-DD HH:mm') )
                    }
                },
                {
                    title: '状态',
                    key: 'status_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        const status = self.status_map[ currentRow.status_id ];
                        const status_desc = status ? status.name: "未知状态: "+ currentRow.status_id;
                        const status_type = status ? status.type : 0;
                        const status_status = status_type == 1 ? 'success': (
                            status_type == 2 ? 'processing': (
                                status_type == 3 ? 'default': 'error'
                            )
                        )
                        return [h( 'Badge', {attrs: {status: status_status,  }} ), 
                                h('span', status_desc)]
                    }
                },
                // 1138575
                {
                    title: '被删除(异常)',
                    key: 'deleted',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let deleted = currentRow.deleted;
                        let deleted_str = deleted == 0 ? '正常': (
                            deleted == 1 ? '被删除': '未知:'+deleted
                        )
                        const deleted_status = deleted == 0 ? 'success': (
                            deleted == 1 ? 'error': 'default:'
                        )
                        return [h( 'Badge', {attrs: {status: deleted_status,  }} ), 
                                h('span', deleted_str)]
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'left',
                    width: 150,
                    render (h, params) {
                        if (self.sport_id === 1){
                            return [oddsButton(self, h, params), incidentsButton(self, h, params)]
                        }else if (self.sport_id === 2){
                            return [ logButton(self, h, params), playerStatsButton(self, h, params), incidentsButton(self, h, params) ];
                        }
                        return [editButton(self, h, params), detailButton(self, h, params), relationButton(self, h, params), ] //delButton(self, h, params) ]
                    }
                }
            ],
            data: [],
            drawer_styles: {
                padding: '2px',
            },
            // 编辑
            modal_edit_unique_tournament: false,
            modal_edit_season: false,
            modal_edit_tournament: false,
            modal_edit_team: false,
            edited_unique_tournament_id: 0,
            edited_season_id: 0,
            edited_tournament_id: 0,
            edited_team_id: 0,
        }
    },
    methods: {
        onPageChange (page) {
            // console.log("1 change", page);
            this.current_page = page;
            this.getData()
        },
        onSearch (search_key) {
            // console.log("2 change", search_key);
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onStatusIdsChange(value){
            // console.log("3 change", value);
            let self = this;
            self.current_page = 1;
            self.status_ids_str = '';
            self.getData();
        },
        onStatusIdsStrChange(value){
            // console.log("4 change", value);
            // console.log( value );
            let self = this;
            if (value){
                // self.status_ids = [];
                // self.status_ids_str.split(",").forEach(item => {
                //     self.status_ids.push(parseInt(item) )
                // })
            } else{
                // self.status_ids = [];
            }
            self.current_page = 1;
            self.getData();
        },
        onDatarangeChange(value){
            // console.log("5 change", value);
            let self = this;
            self.current_page = 1;
            if (value.length > 0){
                self.start_date = parseInt( Date.parse(value[0]) * 0.001 );
                self.end_date   = parseInt( Date.parse(value[1]) * 0.001 );
                // console.log( typeof( self.start_date ), self.start_date );
            }else{
                self.start_date = 0;
                self.end_date = 0;
            }
            self.start_date = self.start_date || 0;
            self.end_date   = self.end_date || 0;
            self.getData();
        },
        // end
        closeRelationDrawer(){
            let self = this;
            self.drawer_relation = false;
            self.relation_match_id = 0;
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit_match = false;
            self.edit_match_id = 0;
        },
        // end
        closeEditModalUniqueTournament(){
            let self = this;
            self.modal_edit_unique_tournament = false;
            self.edited_unique_tournament_id = 0;
        },
        // end
        closeEditModalSeason(){
            let self = this;
            self.modal_edit_season = false;
            self.edited_season_id = 0;
        },
        // end
        closeEditModalTournament(){
            let self = this;
            self.modal_edit_tournament = false;
            self.edited_tournament_id = 0;
        },
        // end
        closeEditModalTeam(){
            let self = this;
            self.modal_edit_team = false;
            self.edited_team_id = 0;
        },
        // end
        editUniqueTournament(_id){
            let self = this;
            self.edited_unique_tournament_id = _id;
            self.modal_edit_unique_tournament = true;
        },
        // end

        refuseFbBk(){
            return [1,2].indexOf(this.sport_id) == -1;
        },
        // 
        editTournament(_id){
            let self = this;
            self.edited_tournament_id = _id;
            self.modal_edit_tournament = true;
        },
        // end
        editSeason(_id){
            let self = this;
            self.edited_season_id = _id;
            self.modal_edit_season = true;
        },
        // end
        editTeam(_id){
            let self = this;
            self.edited_team_id = _id;
            self.modal_edit_team = true;
        },
        // end
        getData () {
            let self = this;
            this.loading = true
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            // 搜索状态
            if (self.status_ids_str){
                var status_ids = self.status_ids_str;
            } else if (this.status_ids ){
                // console.log( this.status_ids, typeof(this.status_ids) );
                var status_ids = this.status_ids.join(',');
            }  else{
                var status_ids = '';
            }
            // console.log("status_ids", self.status_ids_str, status_ids);
            if (self.end_date > 0 && self.end_date === self.start_date){
                self.end_date = self.end_date + 3600 * 24 - 1;
            } 
            var params = {
                sport_id: this.sport_id,
                search_key: this.search_key,
                page: this.current_page,
                start_time: self.start_date,
                end_time  : self.end_date,
                status_ids: status_ids,
                lang: lang,
            }
            if (self.deleted){
                params['deleted'] = 1;
            }
            getMatch(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list
                    this.total_page = response.data.data.total_page
                    this.page_size = response.data.data.page_size
                    this.current_page = response.data.data.current_page
                    this.loading = false
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        
        
        add () {
            // this.edit_data = {
            //     'logo': ''
            // }
            this.edit_match_id = 0;
            this.drawer_edit_match = true
        },
        delete (_id) {
            var params = {
                id: _id,
                type: this.data_type
            }
            // console.log(params);
            deleteData(params).then(response => {
                if (response.data.code == 0) {
                    return true
                } else {
                    this.$Message.error(response.data.msg)
                    return false
                }
            })
        },
        // end 

        // 获取状态
        _init_status(){
            let self = this;
            const status = self.$conf['ballsports']['status'][self.sport_id];
            self.status_union_dic = {
                0: {
                    'ids': [],
                    "name": "全部",
                },
                1: {
                    'ids': [],
                    "name": "未开始",
                },  // 未开始
                2: {
                    'ids': [],
                    "name": "进行中",
                },  // 进行中
                3: {
                    'ids': [],
                    "name": "已结束",
                },  // 已结束
            }
            if (status){
                self.status_map = status.map;
                for (const status_id in self.status_map){
                    const element = self.status_map[ status_id];
                    if (element.type && element.type > 0){
                        self.status_union_dic[element.type].ids.push( element.id )
                    }
                }
            }
        },
        // end 
        jumpDetail(){
            let self = this;
        },
        // end 
    },
    mounted () {
        this.$nextTick(function () {
            this._init_status();
            // 只有网球展示列: 盘数
            // if ( ! this.sport_id || this.sport_id <= 0 ){
            //     this.sport_id = 3;
            // }
            if(this.sport_id !== 3){
                this.columns = this.columns.filter(col => col.key !== 'bestof' );
            }
            this.getData();
        })
    },
    watch: {
        // status_ids (value){
        // },
        deleted(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css";
</style>